<template>
  <div class="connection-detail content">
    <CRow class="mt-1">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="connection-table"
              ref="connectionTable"
              class="zq--table"
              :items="sqsConnections"
              :fields="customFields"
              :column-filter="columnFilter"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:sorter-value="handleSortBy"
              @update:column-filter-value="handleColumnFilter"
              :sorter-value.sync="sorterValue"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <template #id="{ item }">
                <td
                  :class="{'column-shadow-start fixed-column-start': isTableScrollbar}"
                >
                  <router-link :to="{name: 'PreviewConnection', params: {id: item.id}}">
                    {{ item.id }}
                  </router-link>
                </td>
              </template>
              <template #created="{ item }">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #transactionTimestamp="{ item }">
                <td>{{ dateFormate(item.transactionTimestamp) }}</td>
              </template>
              <template #objectType-header>
                <div>Connection Type</div>
              </template>
              <template #id-filter>
                <FilterSettings
                  field="id"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #name-filter>
                <FilterSettings
                  field="id"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #created-filter>
                <FilterSettings
                  field="created"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #spaceName-filter>
                <FilterSettings
                  field="spaceName"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #objectType-filter>
                <FilterSettings
                  field="objectType"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #select-filter>
                <div></div>
              </template>

              <template #actions-header>
                <div v-theme-header-actions></div>
              </template>
              <template #actions-filter>
                <div></div>
              </template>
            </CDataTable>
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="resultCount"
              :itemsPerPage="itemsPerPage"
              :disabled="!ready"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TableLoader from '@/components/table/Loader';
import FilterSettings from '@/components/table/ColumnFilter';
import TableFooter from '@/components/table/Footer';
import { mapActions, mapGetters } from "vuex";
import {pageConfig} from '@/config';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { dateFormate } from '@/utils/dateFormate';

export default {
  name: "ConnectionsDetails",
  components: {
    TableLoader,
    FilterSettings,
    TableFooter,
  },
  data() {
    return {
      connectionsData: [],
      isTableScrollbar: false,
      customFields: [],
      dropDownActions: [],
      query: {
        must: [
          {
            queryField: 'options.key',
            queryValues: [
              'transformerId'
            ]
          },
          {
            queryField: 'options.value',
            queryValues: [
              this.$route.params.id
            ]
          }
        ],
        skip: 0,
        limit: 20
      },
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      ready: false,
    }
  },
  computed: {
    ...mapGetters('sqsConnections', [
      'sqsConnections',
      'fields',
      'pages',
      'loading',
      'resultCount',
      'totalRecords',
      'originalFields',
      'searchableAllFields',
      'success',
      'message',
    ]),
    ...mapGetters('theme', ['theme']),
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        }
      }
    },
    isDefaultTheme() {
      return this.theme === 'default'
    },
  },
  mounted() {
    this.customFields = this.fields.filter(field => {
      return field !== 'select' && field !== 'actions';
    });
    this.initialize();
  },
  methods: {
    ...mapActions('sqsConnections', ['handleGetConnectionsByQuery']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);
      this.handleGetConnectionsByQuery({queryRequest: this.query})
        .then((items) => {
          this.hasAtLeastOne = !!items.length;
          this.ready = true;
        });
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    dropDownChoice(action, item) {
      if (this[action]) {
        this[action](item)
      }
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetConnectionsByQuery({queryRequest: this.query});
    },
    paginationChange(val) {
      this.page = val;
      this.handleGetConnectionsByQuery({queryRequest: this.query});
    },
    handleSortBy(e) {
      this.sortBy = [
        {
          queryField: e.column,
          order: e.asc ? 'ASC' : 'Desc',
        },
      ];
      this.page = 1;
      this.handleGetConnectionsByQuery({queryRequest: this.query});
    },
    handleColumnFilter(e) {
      if (e.field && e.value) {
        this.query[e.field] = e.value.trim();
      } else {
        delete this.query[e.field];
      }
      this.handleGetConnectionsByQuery({queryRequest: this.query});
    },
  },
}
</script>

<style scoped>

</style>