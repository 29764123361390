<template>
  <div class="preview-transformer">
    <!--   Header   -->
    <div class="zq-page-title-wrapper d-flex">
        <h3 class="zq-page-title">{{ formData.name }} </h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>

    <CTabs class="zq--tabs" :active-tab="activeTab" @update:activeTab="setActiveTab">
      <CTab :title="texts.previewPage.transformerTab">
        <TransformerDetails></TransformerDetails>
      </CTab>
      <CTab :title="texts.previewPage.codeTab">
        <div class="transformer-detail-page content">
          <codemirror
            ref="myCm"
            class="zq--codemirror"
            :options="options"
            :value="this.formData.source"
          >
          </codemirror>
        </div>
      </CTab>
      <CTab :title="texts.previewPage.connectionsTab">
        <ConnectionsDetails v-if="activeTab === 2" />
      </CTab>
      <CTab :title="texts.previewPage.systemLogsTab" v-if="entityId">
        <EntityLogs :entityId="entityId" v-if="activeTab === 3" />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import TransformerDetails from '@/generated/ziqni/views/transformers/TransformerDetails';
import ConnectionsDetails from './details/ConnectionsDetails';
import EntityLogs from '@/shared/components/supportModels/logEvents/EntityLogs.vue';
import { mapGetters } from "vuex";
import 'codemirror/mode/javascript/javascript.js';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/autorefresh.js';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { transformers } from "@/config/descriptions/transformers";
import { transformersTexts } from "@/config/pageTexts/transformers.json";

export default {
  name: 'PreviewTransformer',
  components: {
    EntityLogs,
    TransformerDetails,
    ConnectionsDetails,
    codemirror,
    IconWithTooltip
  },
  data() {
    return {
      texts: {
        ...transformersTexts
      },
      descriptions: {
        ...transformers.preview
      },
      formData: {
        name: '',
        source: '',
      },
      options: {
        lineNumbers: true,
        readOnly: true,
        autoRefresh: true,
      },
      activeTab: 0,
      entityId: null,
    };
  },
  computed: {
    ...mapGetters('transformers', ['transformer']),
  },
  mounted() {
    this.entityId = this.$route.params.id;
  },
  watch: {
    transformer: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
  },
  methods: {
    setActiveTab(val) {
      this.activeTab = val;
    },
  }
}
</script>

<style lang="scss">
.preview-transformer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.transformer-detail-page {
  height: 100%;
  flex: 2;
  background: var(--zq-sub-bg);

  .CodeMirror {
    height: 80%;
  }
}


</style>